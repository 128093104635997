import React, { useState, useEffect, useMemo, useRef } from "react"
import Header from "./Header"
import Footer from "./Footer"

import { IconButton, Tooltip, Box, Checkbox, FormControlLabel } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import MaterialReactTable from "material-react-table"

import Axios from "axios"
import { CSVLink } from "react-csv"

const wfoStatusStyle = {
    bgColor: "#159906",
    fontColor: "#fff"
}
const statusStyle = {
    OOO: {
        bgColor: "#f20a21",
        fontColor: "#fff"
    },
    PTO: {
        bgColor: "#f20a21",
        fontColor: "#fff"
    },
    OSD: {
        bgColor: "#f20a21",
        fontColor: "#fff"
    },
    OTD: {
        bgColor: "#ff6600",
        fontColor: "#fff"
    },
    OBT: {
        bgColor: "#ff6600",
        fontColor: "#fff"
    },
    OHL: {
        bgColor: "#f20a21",
        fontColor: "#fff"
    },
    WFH: {
        bgColor: "#94fc74",
        fontColor: "2b2b2b"
    }
}

let today = new Date()
today = new Date(today.getTime() - today.getTimezoneOffset() * 60 * 1000)
const dateToday = today.toISOString().split("T")[0]

function PresenceData(props) {
    const [data, setData] = useState([])
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isRefetching, setIsRefetching] = useState(false)
    const [columnFilters, setColumnFilters] = useState([])
    const [departmentChecked, setDepartmentChecked] = useState(false)

    const department = props.user.department

    const loadPresenceData = async () => {
        setIsRefetching(true)

        if (!props.isSessionActive()) {
            window.location.reload()
        }

        const apiEndpoint = `${props.Config["presence.apiEndpoint"]}/date/${dateToday}`
        console.log("API endpoint - ", apiEndpoint)

        if (!data.length) {
            setIsLoading(true)
        }
        try {
            const options = {
                headers: { Authorization: props.accessToken }
            }
            const response = await Axios.get(apiEndpoint, options)
            if (response.data.success) {
                console.log(`Successfully loaded the Presence data from ${apiEndpoint}`)
                setData(response.data.data.data)
                console.log(`Will set the default filter for department to ${department}`)
            }
        } catch (error) {
            setIsError(true)
            setIsLoading(false)
            setIsRefetching(false)
            console.error(error)
            return
        }
        setIsError(false)
        setIsLoading(false)
        setIsRefetching(false)
    }
    useEffect(() => {
        loadPresenceData()
    }, [])

    useEffect(() => {
        console.log(columnFilters)
        if (!columnFilters.length) {
            setDepartmentChecked(false)
        }
        let foundDepartment = false
        columnFilters.map(({ id, value }) => {
            if (id === "department") {
                foundDepartment = true
                if (value?.toLowerCase() != department.toLowerCase()) setDepartmentChecked(false)
            }
        })
        if (!foundDepartment) {
            setDepartmentChecked(false)
        }
    }, [columnFilters])

    const columns = useMemo(
        () => [
            {
                accessorKey: "user",
                header: "USERNAME",
                size: 30,
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                filterFn: "contains",
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Username",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
            },
            {
                accessorKey: "status",
                header: "STATUS",
                size: 50,
                //custom conditional format and styling
                Cell: ({ cell }) => (
                    <Box
                        sx={theme => ({
                            backgroundColor: (statusStyle[cell.getValue()] && statusStyle[cell.getValue()]["bgColor"]) || wfoStatusStyle["bgColor"],
                            borderRadius: "0.25rem",
                            color: (statusStyle[cell.getValue()] && statusStyle[cell.getValue()]["fontColor"]) || wfoStatusStyle["fontColor"],
                            display: "inline-block",
                            padding: "5px",
                            textAlign: "center"
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                filterFn: "contains",
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Status",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
            },
            {
                accessorKey: "name",
                header: "NAME",
                size: 100,
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "right"
                },
                filterFn: "contains",
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Name",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
            },
            {
                accessorKey: "desk",
                header: "DESK",
                size: 30,
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                filterFn: "contains",
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Location",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
            },
            {
                accessorKey: "office",
                header: "ASSIGNED OFFICE",
                size: 50,
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "center"
                },
                filterFn: "contains",
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Office",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
            },
            {
                accessorKey: "department",
                header: "DEPARTMENT",
                size: 150,
                muiTableHeadCellProps: {
                    align: "center"
                },
                muiTableBodyCellProps: {
                    align: "left"
                },
                muiTableHeadCellFilterTextFieldProps: {
                    placeholder: "Department",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                textAlign: "center"
                            }
                        }
                    }
                }
                // filterFn: "contains" // TODO - fix this
            }
        ],
        []
    )
    let headers = [
        { label: "Username", key: "user" },
        { label: "Status", key: "status" },
        { label: "Date", key: "date" },
        { label: "Name", key: "name" },
        { label: "Desk", key: "desk" },
        { label: "Office", key: "office" },
        { label: "Department", key: "department" }
    ]
    return (
        <>
            <Header user={props.user.name} />
            <div className="container">
                <MaterialReactTable
                    columns={columns}
                    data={data ?? []} //fallback to array if data is undefined
                    globalFilterFn="contains"
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: "error",
                                children: "Error loading data"
                            }
                            : undefined
                    }
                    state={{
                        isLoading,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        showSkeletons: isRefetching,
                        columnFilters
                    }}
                    initialState={{
                        density: "compact",
                        pagination: { pageIndex: 0, pageSize: 25 }
                    }}
                    muiTableContainerProps={({ table }) => ({
                        sx: {
                            padding: "15px",
                            display: "inline-block",
                            maxHeight: "900px"
                        }
                    })}
                    renderTopToolbarCustomActions={({ table }) => (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={departmentChecked}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setDepartmentChecked(true)

                                                setColumnFilters(prev => {
                                                    const filters = prev.map(({ id, value }) => {
                                                        if (id === "department") {
                                                            return { id: "department", value: department }
                                                        } else {
                                                            return { id, value }
                                                        }
                                                    })
                                                    return [...filters, { id: "department", value: department }]
                                                })
                                                table.setShowFilters(true)
                                            } else {
                                                setDepartmentChecked(false)
                                                setColumnFilters(prev => prev.filter(val => val.id != "department"))
                                            }
                                        }}
                                    ></Checkbox>
                                }
                                label="Filter by my department"
                            />
                            <Tooltip arrow title="Export to CSV">
                                <IconButton id="exportButton">
                                    <CSVLink data={data} headers={headers} separator={","} filename={"presence-export-" + dateToday + ".csv"}>
                                        <FileDownloadIcon />
                                    </CSVLink>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => loadPresenceData()}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    muiTopToolbarProps={() => ({
                        sx: {
                            justifyContent: "flex-end"
                        }
                    })}
                    muiLinearProgressProps={({ isTopToolbar }) => ({
                        color: "inherit",
                        sx: {
                            display: isTopToolbar ? "block" : "none" //hide bottom progress bar
                        }
                    })}
                    localization={{
                        noResultsFound: isLoading ? "Loading..." : "No results found! Please review the applied filters."
                    }}
                    muiTablePaperProps={{
                        elevation: 0
                    }}
                    onColumnFiltersChange={setColumnFilters}
                />
            </div>
            <Footer />
        </>
    )
}

export default PresenceData
