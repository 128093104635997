import React, { useState } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Components/Home"
import LoggedOut from "./Components/LoggedOut"
const Config = require("Config")
import jwt_decode from "jwt-decode"

function Main() {
  const stage = process.env.STAGE

  if (stage !== "beta") {
    console.log = function no_console() {}
  }

  function isSessionActive() {
    const accessToken = localStorage.getItem("accessToken")
    const idToken = localStorage.getItem("idToken")

    if (!Boolean(accessToken)) {
      console.log("accessToken not found in localStorage! Logging out!")
      return false
    }
    if (!Boolean(idToken)) {
      console.log("idToken not found in localStorage! Logging out!")
      return false
    }
    const expiry = new Date(jwt_decode(accessToken).exp * 1000)
    const now = new Date()
    const fiveMinutes = 1000 * 60 * 5

    console.log(`Current time - ${now}. Session expires at ${expiry}`)
    const remainingTime = expiry.getTime() - now.getTime()
    if (remainingTime < fiveMinutes) {
      console.log("Session is about to expire soon. Logging out!")
      localStorage.clear()
      return false
    }
    console.log("Session is active for another " + remainingTime / (1000 * 60 * 60) + " hours.")
    return true
  }
  function setUserFromLocalStorage() {
    try {
      const idToken = jwt_decode(localStorage.getItem("idToken"))
      const user = {
        user: idToken["custom:username"],
        name: idToken["name"],
        email: idToken["email"],
        office: idToken["custom:office"],
        department: idToken["custom:department"],
        groups: idToken["custom:groups"]
      }

      return user
    } catch {
      return null
    }
  }

  const [loggedIn, setLoggedIn] = useState(isSessionActive())
  const [user, setUser] = useState(setUserFromLocalStorage())
  const [idToken, setIdToken] = useState(localStorage.getItem("idToken"))
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={loggedIn ? <Home user={user} Config={Config[stage]} accessToken={accessToken} isSessionActive={isSessionActive} /> : <LoggedOut setLoggedIn={setLoggedIn} setUser={setUser} Config={Config[stage]} setIdToken={setIdToken} setAccessToken={setAccessToken} />} />
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.querySelector("#app"))
root.render(<Main />)

if (module.hot) {
  module.hot.accept()
}
