import React from "react"

function Footer(props) {
  
  
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">
          © 2022 Copyright Arcesium
        </span>
      </div>
    </footer>
  );
}

export default Footer
