import React, { useEffect } from "react"
import Axios from "axios"

import jwt_decode from "jwt-decode"

function LoggedOut(props) {
  const Config = props.Config
  const clientId = Config["auth.clientId"]
  const redirectUri = Config["auth.redirectUri"]
  const authServerEndpoint = Config["auth.authServerEndpoint"]

  const tokenExchangeEndpoint = `${authServerEndpoint}/token`
  const authorizationEndpoint = `${authServerEndpoint}/authorize?identity_provider=AzureAD&redirect_uri=${redirectUri}&response_type=CODE&client_id=${clientId}&scope=email+openid+phone+profile+presence/get`

  const params = new URLSearchParams(window.location.search)
  const authCode = params.get("code")
  if (authCode) {
    // SSO done. Exchange authorization code for token
    window.history.pushState("", "", "/")
    async function exchangeAuthzCodeForToken() {
      try {
        const data = {
          code: authCode,
          grant_type: "authorization_code",
          client_id: clientId,
          redirect_uri: redirectUri
        }
        const options = {
          headers: { "content-type": "application/x-www-form-urlencoded" }
        }
        const response = await Axios.post(tokenExchangeEndpoint, data, options)

        const idToken = jwt_decode(response.data.id_token)
        const user = {
          user: idToken["custom:username"],
          name: idToken["name"],
          email: idToken["email"],
          office: idToken["custom:office"],
          department: idToken["custom:department"],
          groups: idToken["custom:groups"]
        }
        localStorage.setItem("idToken", response.data.id_token)
        localStorage.setItem("accessToken", response.data.access_token)

        props.setLoggedIn(true)
        props.setUser(user)
        props.setIdToken(response.data.id_token)
        props.setAccessToken(response.data.access_token)

        console.log("Authentication successful!")
      } catch (error) {
        console.log("There was an error - ", error)
      }
    }
    exchangeAuthzCodeForToken()
  } else {
    // Redirect for SSO
    console.log("Redirecting for login to - ", authorizationEndpoint)
    window.location.replace(authorizationEndpoint)
  }

  return <p>Logging you in and loading data!</p>
}

export default LoggedOut
