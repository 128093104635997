import React from "react"

function Header({ user }) {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: "#0052cc" }}>
        <img className="logo" src="/assets/arc-logo.svg" />
        {/* <a className="navbar-brand" href="#">
          PRESENCE
        </a> */}
        {/* <img className="title" src="../assets/presence-logo.png" /> */}
        <h1 className="title">PRESENCE</h1>
        {user && <div style={{ color: "white" }}>Welcome {user}!</div>}
      </nav>
    </header>
  )
}

export default Header
